import { ElementType, lazy, Suspense } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import MainLayout from '../layouts/main';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'privacy-policy', element: <PrivacyPolicy /> },
        { path: 'terms-of-use', element: <TermsOfUse /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

const HomePage = Loadable(lazy(() => import('../pages/Home')));
const PrivacyPolicy = Loadable(lazy(() => import('../pages/PrivacyPolicy')));
const TermsOfUse = Loadable(lazy(() => import('../pages/TermsOfUse')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
