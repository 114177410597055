import axios, { AxiosAdapter } from 'axios';
import { HOST_API } from '../config';
import throttleAdapterEnhancer from 'src/apis/adapters/throttleAdapterEnhancer';
import cacheAdapterEnhancer from 'src/apis/adapters/cacheAdapterEnhancer';

const axiosInstance = axios.create({
  baseURL: HOST_API,
  adapter: throttleAdapterEnhancer(cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter), {
    threshold: 2000,
  }),
});

export default axiosInstance;
