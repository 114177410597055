import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, Button, Container, Stack, Toolbar } from '@mui/material';
import useOffSetTop from '../../hooks/useOffSetTop';
import cssStyles from '../../utils/cssStyles';
import { HEADER } from 'src/config';
import Logo from '../../components/Logo';
import LoginDialog from 'src/components/dialogs/login/LoginDialog';
import NiceModal from '@ebay/nice-modal-react';
import { useCallback } from 'react';
import useAuth from 'src/hooks/useAuth';
import AccountPopover from 'src/layouts/main/AccountPopover';

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: HEADER.MOBILE_HEIGHT,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('md')]: {
    height: HEADER.MAIN_DESKTOP_HEIGHT,
  },
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8,
}));

// ----------------------------------------------------------------------

export default function MainHeader() {
  const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT);
  const theme = useTheme();

  const { isLoggedIn } = useAuth();

  const loginHandler = useCallback((): void => {
    NiceModal.show(LoginDialog, { signUp: false });
  }, []);

  const signUpHandler = useCallback((): void => {
    NiceModal.show(LoginDialog, { signUp: true });
  }, []);

  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent' }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            ...cssStyles(theme).bgBlur(),
            height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
          }),
        }}
      >
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Logo />

          <Box sx={{ flexGrow: 1 }} />

          <Stack direction={'row'} spacing={1.5}>
            {isLoggedIn ? (
              <>
                <AccountPopover />
              </>
            ) : (
              <>
                <Button variant={'text'} onClick={loginHandler}>
                  Login
                </Button>

                <Button variant="contained" onClick={signUpHandler}>
                  Signup
                </Button>
              </>
            )}
          </Stack>
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
