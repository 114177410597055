import Router from './routes';
import ThemeProvider from './theme';
import ThemeSettings from './components/settings';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import NiceModal from '@ebay/nice-modal-react';
import ServerApiManager from 'src/managers/ServerApiManager';
import { ExamResultsProvider } from 'src/contexts/ExamResultContext';
import CookieConsent from 'src/components/CookieConsent';

export default function App() {
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <NotistackProvider>
            <NiceModal.Provider>
              <ServerApiManager />
              <ExamResultsProvider>
                <ProgressBarStyle />
                <ChartStyle />
                <ScrollToTop />
                <CookieConsent />
                <Router />
              </ExamResultsProvider>
            </NiceModal.Provider>
          </NotistackProvider>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
