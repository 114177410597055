import useGenericRequest, { GenericRequestState } from '../base/useGenericRequest';
import { PartialDeep } from 'type-fest';
import { AbstractJpaRO, BaseEntity } from 'src/@types/entity';

interface Options {}

function useCrudCreate<RequestRO, ResponseRO extends AbstractJpaRO>(
  entity: BaseEntity,
  dataItem: PartialDeep<RequestRO> | undefined,
  options?: Options
): GenericRequestState<ResponseRO> {
  return useGenericRequest<ResponseRO>(
    {
      url: `${entity.api.path}`,
      method: 'POST',
      data: dataItem,
    },
    {
      manual: true,
      clearCache: true,
      cacheName: entity.api.cacheName,
    }
  );
}
export default useCrudCreate;
