import * as React from 'react';
import { FunctionComponent, PropsWithChildren } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import { SxProps } from '@mui/system/styleFunctionSx/styleFunctionSx';
import { Theme } from '@mui/material/styles/createTheme';
import Iconify from 'src/components/Iconify';
import { IconButtonAnimate } from 'src/components/animate';
import { Box, Stack } from '@mui/material';

interface IProps extends PropsWithChildren<any> {
  onClose?: () => void;
  sx?: SxProps<Theme>;
}

const DialogTitleEnhanced: FunctionComponent<IProps> = ({ onClose, sx, children }) => {
  return (
    <>
      <DialogTitle sx={sx}>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <Box>{children}</Box>

          {!!onClose && (
            <IconButtonAnimate
              aria-label="close"
              size="medium"
              onClick={onClose}
              sx={{ float: 'right' }}
            >
              <Iconify icon={'eva:close-fill'} />
            </IconButtonAnimate>
          )}
        </Stack>
      </DialogTitle>
      <div />
    </>
  );
};
export default DialogTitleEnhanced;
