import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ExamResultRO } from 'src/@types/model';
import useAuth from 'src/hooks/useAuth';
import useCrudSearch from 'src/apis/crud/useCrudSearch';
import { examResultEntity } from 'src/entities/exam-result.entity';
import useCrudCreate from 'src/apis/crud/useCrudCreate';
import useCrudUpdate from 'src/apis/crud/useCrudUpdate';
import { last, nth } from 'lodash';
import { useSnackbar } from 'notistack';
import ReactGA from 'react-ga4';

export type ExamResultsContextProps = {
  currentExamResult?: ExamResultRO;
  historyExamResults?: ExamResultRO[];
  datedExamResults: ExamResultRO[];
  latestExamResults?: ExamResultRO;
  beforeLatestExamResults?: ExamResultRO;
  loading: boolean;
  addExamResults: (leftEye: number, rightEye: number, date: Date | null) => void;
  updateLatestExamResults: (improvementComment: string) => void;
};

const ExamResultsContext = React.createContext<ExamResultsContextProps>(undefined!);

export interface ExamResultsProviderProps extends PropsWithChildren<any> {}

const ExamResultsProvider = ({ children }: ExamResultsProviderProps) => {
  const { isLoggedIn } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [currentExamResult, setCurrentExamResult] = useState<ExamResultRO | undefined>(undefined);
  const [updateExamResult, setUpdateExamResult] = useState<ExamResultRO | undefined>(undefined);
  const [historyExamResults, setHistoryExamResults] = useState<ExamResultRO[] | undefined>(
    undefined
  );

  const datedExamResults = useMemo<ExamResultRO[]>(
    () =>
      historyExamResults
        ?.filter((r) => !!r.examDate)
        ?.sort((r1, r2) => (r1.examDate || 0) - (r2.examDate || 0)) || [],
    [historyExamResults]
  );

  const latestExamResults = useMemo<ExamResultRO | undefined>(
    () => last(datedExamResults),
    [datedExamResults]
  );
  const beforeLatestExamResults = useMemo<ExamResultRO | undefined>(
    () => nth(datedExamResults, -2),
    [datedExamResults]
  );

  const updateHistoryExamResults = useCallback(
    (examResult: ExamResultRO): void => {
      setHistoryExamResults((currentHistoryExamResults) =>
        currentHistoryExamResults
          ? [examResult, ...currentHistoryExamResults.filter((r) => r.id !== examResult.id)]
          : currentHistoryExamResults
      );
    },
    [setCurrentExamResult, setHistoryExamResults]
  );

  const createState = useCrudCreate<ExamResultRO, ExamResultRO>(
    examResultEntity,
    currentExamResult
  );

  useEffect(() => {
    const createdExamResult = createState.result;
    if (createdExamResult) {
      setCurrentExamResult(createdExamResult);
      updateHistoryExamResults(createdExamResult);
    }
  }, [createState.result]);

  useEffect(() => {
    if (isLoggedIn && currentExamResult && !currentExamResult.id) {
      createState.executeRequest();
    }
  }, [isLoggedIn, currentExamResult]);

  const updateState = useCrudUpdate<ExamResultRO>(examResultEntity, updateExamResult);

  useEffect(() => {
    const updatedExamResult = updateState.result;
    if (updatedExamResult) {
      updateHistoryExamResults(updatedExamResult);
      enqueueSnackbar('Submitted successfully.', { variant: 'success' });
    }
  }, [updateState.result]);

  useEffect(() => {
    if (isLoggedIn && updateExamResult) {
      updateState.executeRequest();
    }
  }, [isLoggedIn, updateExamResult]);

  const historyState = useCrudSearch<ExamResultRO>(examResultEntity, {
    currentPage: 1,
    pageSize: 50,
    orders: [{ by: 'id', descending: true }],
    manual: true,
    count: false,
    cache: false,
    throttle: true,
  });

  useEffect(() => {
    const examResults = historyState.result;
    if (examResults) {
      const createdExamResult = createState.result;
      setHistoryExamResults([
        ...(createdExamResult ? [createdExamResult] : []),
        ...examResults.filter((r) => r.id !== createdExamResult?.id),
      ]);
    }
  }, [historyState.result]);

  useEffect(() => {
    if (isLoggedIn) {
      historyState.executeRequest();
    } else {
      setCurrentExamResult(undefined);
      setHistoryExamResults(undefined);
    }
  }, [isLoggedIn]);

  const addExamResults = useCallback(
    (leftEye: number, rightEye: number, date: Date | null): void => {
      if (
        currentExamResult &&
        currentExamResult.leftEye === leftEye &&
        currentExamResult.rightEye === rightEye &&
        ((!currentExamResult.examDate && !date) || currentExamResult?.examDate === date?.getTime())
      ) {
        return;
      }

      setCurrentExamResult({
        id: 0,
        leftEye: leftEye,
        rightEye: rightEye,
        examDate: date?.getTime(),
      });

      ReactGA.event({
        category: 'Test Results',
        action: 'Added Test Results',
      });
    },
    [currentExamResult, setCurrentExamResult]
  );

  const updateLatestExamResults = useCallback(
    (improvementComment: string): void => {
      setUpdateExamResult(
        latestExamResults
          ? {
              ...latestExamResults,
              improvementComment: improvementComment,
            }
          : latestExamResults
      );

      ReactGA.event({
        category: 'Test Results',
        action: 'Updated Improvement Comment',
      });
    },
    [latestExamResults, setUpdateExamResult]
  );

  const loading = useMemo<boolean>(
    () => createState.loading || updateState.loading,
    [createState.loading, updateState.loading]
  );

  return (
    <ExamResultsContext.Provider
      value={{
        currentExamResult,
        historyExamResults,
        datedExamResults,
        latestExamResults,
        beforeLatestExamResults,
        loading,
        addExamResults,
        updateLatestExamResults,
      }}
    >
      {children}
    </ExamResultsContext.Provider>
  );
};

const useExamResults = (): ExamResultsContextProps => {
  const context = useContext(ExamResultsContext);

  if (!context) throw new Error('ExamResultsContext must be used inside ExamResultsProvider');

  return context;
};

export { ExamResultsContext, ExamResultsProvider, useExamResults };
