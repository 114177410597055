import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './slices/auth';
import { PersistConfig } from 'redux-persist/es/types';
import { AuthState } from 'src/@types/auth';

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const authPersistConfig: PersistConfig<any> = {
  key: 'auth',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['token', 'isLoggedIn'],
};

const rootReducer = combineReducers({
  auth: persistReducer<AuthState>(authPersistConfig, authReducer),
});

export { rootPersistConfig, rootReducer };
