import { BaseEntity } from 'src/@types/entity';

export const examResultEntity: BaseEntity = {
  name: 'ExamResult',
  api: {
    path: '/secure/examResult',
    cacheName: 'examResult',
    defaultOrders: [{ by: 'id', descending: true }],
  },
};
