import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Grid, Link, Divider, Container, Typography, Stack } from '@mui/material';
import { PATH_PAGE } from 'src/routes/paths';
import LogoFooter from 'src/components/LogoFooter';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'Legal',
    children: [
      { name: 'Terms of Use', href: PATH_PAGE.termsOfUse, external: false },
      { name: 'Privacy Policy', href: PATH_PAGE.privacyPolicy, external: false },
    ],
  },
  {
    headline: 'Contact',
    children: [
      { name: 'support@trukera.com', href: 'mailto:support@trukera.com', external: true },
      { name: '1-855-832-7522', href: 'tel:1-855-832-7522', external: true },
    ],
  },
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  return (
    <RootStyle>
      <Divider />
      <Container sx={{ py: 10 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={12} sx={{ mb: 3 }}>
            <LogoFooter sx={{ mx: { xs: 'auto', md: 'inherit' } }} disabledLink />
          </Grid>

          <Grid item xs={12}>
            <Stack
              spacing={5}
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="space-between"
            >
              <Stack spacing={2.5} sx={{ maxWidth: { md: 500 }, pr: { md: 3 } }}>
                <Typography component="p" variant="overline">
                  References
                </Typography>
                <Stack spacing={1}>
                  <Typography variant={'caption'}>
                    1. Bron AJ, de Paiva CS, Chauhan SK, Bonini S, Gabison EE, Jain S, Knop E,
                    Markoulli M, Ogawa Y, Perez V, Uchino Y, Yokoi N, Zoukhri D, Sullivan DA. TFOS
                    DEWS II pathophysiology report. Ocul Surf. 2017 Jul;15(3):438-510. doi:
                    10.1016/j.jtos.2017.05.011. Epub 2017 Jul 20. Erratum in: Ocul Surf. 2019
                    Oct;17(4):842. PMID: 28736340.
                  </Typography>
                  <Typography variant={'caption'}>
                    2. Akpek EK, Amescua G, Farid M, et al. Dry eye syndrome preferred practice
                    pattern. Ophthalmology. 2019;126(1):P286-P334.
                  </Typography>
                  <Typography variant={'caption'}>
                    3. Hirata H, Mizerska K, Marfurt CF, Rosenblatt MI. Hyperosmolar tears induce
                    functional and structural alterations of corneal nerves: electrophysiological
                    and anatomical evidence toward neurotoxicity. Invest Ophthal Vis Sci.
                    2015;56(13):8125-8140.
                  </Typography>
                  <Typography variant={'caption'}>
                    4. Huet E, Vallee B, Delbe J, Mourah S, Pruliere-Escabasse V, Tremouilleres M,
                    Kadmatsu K, Doan S, Baudouin C, Menashi S, Gabison EE. Emmprin modulates
                    epithelial barrier function through a MMP–mediated occludin cleavage. Amer J
                    Pathol. 2011;179(3):1278-1286.
                  </Typography>
                  <Typography variant={'caption'}>
                    5. Erstad BL. Osmolality and Osmolarity: Narrowing the Terminology Gap.
                    Pharmacotherapy. 2003;23(9):1085-1086.
                  </Typography>
                </Stack>
              </Stack>

              {LINKS.map((list) => (
                <Stack key={list.headline} spacing={2}>
                  <Typography component="p" variant="overline">
                    {list.headline}
                  </Typography>
                  {list.children.map((link) =>
                    link.external ? (
                      <Link
                        href={link.href}
                        target="_blank"
                        rel="noopener"
                        key={link.name}
                        color="inherit"
                        variant="body2"
                        sx={{ display: 'block' }}
                        noWrap
                      >
                        {link.name}
                      </Link>
                    ) : (
                      <Link
                        to={link.href}
                        key={link.name}
                        color="inherit"
                        variant="body2"
                        component={RouterLink}
                        sx={{ display: 'block' }}
                        noWrap
                      >
                        {link.name}
                      </Link>
                    )
                  )}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 5,
            fontSize: 13,
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          © 2022. All rights reserved
        </Typography>
      </Container>
    </RootStyle>
  );
}
