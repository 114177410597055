import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function LogoFooter({ disabledLink = false, sx }: Props) {
  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.primary.main;
  const SECONDARY_MAIN = theme.palette.secondary.main;

  const logo = (
    <Box sx={{ width: 140, height: 40, ...sx }}>
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 358.0518 102.543">
        <path id="fullLogo" d="M19.2695,10.3545H0V3.3691H46.4893v6.9854H27.2188V59.5732h-7.9492V10.3545Z" fill={SECONDARY_MAIN}/>
        <path id="fullLogo-2" data-name="fullLogo" d="M76.0342,16.6172v7.4673c-.6416-.0806-1.2041-.0806-1.7666-.0806-8.2695,0-13.4082,5.0586-13.4082,14.3721v21.1973h-7.708V17.0185h7.3867v7.146c2.7295-4.978,8.0293-7.5474,15.4961-7.5474Z" fill={SECONDARY_MAIN}/>
        <path id="fullLogo-3" data-name="fullLogo" d="M127.3389,17.0185V59.5732h-7.3066v-6.4233c-3.1309,4.416-8.4307,6.9053-14.4521,6.9053-11,0-18.3066-6.022-18.3066-18.5474V17.0185h7.708v23.606c0,8.3501,4.1748,12.5254,11.4814,12.5254,8.0293,0,13.168-4.978,13.168-14.1313V17.0185h7.708Z" fill={SECONDARY_MAIN}/>
        <path id="fullLogo-4" data-name="fullLogo" d="M180.3666,34.7555c.0772-.1024,.2431-.3213,.4844-.6369l-.54,.5662,.0556,.0707Z" fill={PRIMARY_MAIN}/>
        <path id="fullLogo-5" data-name="fullLogo" d="M281.3188,20.3934v-4.7371h-17.262v9.0766c3.2127,3.4824,6.0161,7.438,8.2823,11.8193,0,0-3.2623,7.2783-8.2823,12.3756v10.6464h18.1459v-19.5099c0-6.9855,3.8532-9.4743,9.3134-9.4743,1.2844,0,2.408,.0804,4.0141,.3213V14.8532c-6.102,0-10.9991,1.847-14.2114,5.5402Z" fill={PRIMARY_MAIN}/>
        <path id="fullLogo-6" data-name="fullLogo" d="M319.9389,14.8532c-6.8242,0-14.6119,1.7666-19.7504,4.978l5.7803,11.9631c2.8905-2.1679,7.3068-3.4523,11.2404-3.4523,5.1385,0,7.7081,1.7662,8.2695,5.1385h-7.306c-13.6492,0-20.0721,4.7367-20.0721,13.2475,0,7.5472,5.5398,13.6492,16.2985,13.6492,6.1824,0,10.3573-2.1679,12.4452-6.5837v5.7807h16.8598v-23.6048c0-14.6123-8.6708-21.116-23.7653-21.116Zm5.6202,30.4294c-.9635,2.8905-3.2114,4.1749-5.7006,4.1749-2.7297,0-4.335-1.5253-4.335-3.5327,0-2.4084,1.6857-3.6932,5.5398-3.6932h4.4958v3.051Z" fill={PRIMARY_MAIN}/>
        <path id="fullLogo-7" data-name="fullLogo" d="M189.1434,49.0454v-.0002c-3.5833-3.7943-8.8304-14.2189-8.8304-14.2189h0s5.9373-7.7042,8.8304-10.7678c2.5367-2.6866,6.536-6.4048,9.3126-8.4023h-21.4369l-12.6053,12.7657V0h-18.1459V59.574h18.1459v-10.3573l2.8897-3.131,10.6791,13.4883h21.9187c-2.8012-2.1358-8.0148-7.6935-10.7579-10.5286Z" fill={PRIMARY_MAIN}/>
        <path id="fullLogo-8" data-name="fullLogo" d="M250.8612,41.4476h-30.5098c1.2844,3.3723,4.3358,5.1385,8.8316,5.1385,4.0149,0,6.2628-1.0439,9.1534-3.2114l9.4734,9.5543c-4.2546,4.6567-10.437,7.0655-19.1882,7.0655-16.1384,0-26.5762-9.7151-26.5762-22.8022,0-13.3279,10.6791-22.7217,24.8896-22.7217,13.0066,0,24.1666,7.8685,24.1666,22.7217,0,1.2848-.16,2.9706-.2405,4.2554Zm-30.8307-8.7516h14.131c-.723-3.6932-3.3723-5.8607-7.0655-5.8607-3.7736,0-6.3433,2.1675-7.0655,5.8607Z" fill={PRIMARY_MAIN}/>
        <path id="fullLogo-9" data-name="fullLogo" d="M46.9961,76.2466v26.0728h-2.6445v-20.8584l-10.2422,17.5435h-1.3037l-10.2432-17.4316v20.7466h-2.6445v-26.0728h2.2725l11.3223,19.3311,11.2119-19.3311h2.2715Z" fill={SECONDARY_MAIN}/>
        <path id="fullLogo-10" data-name="fullLogo" d="M99.2646,99.9355v2.3838h-18.4375v-26.0728h17.8789v2.3838h-15.123v9.2744h13.4834v2.3467h-13.4834v9.6841h15.6816Z" fill={SECONDARY_MAIN}/>
        <path id="fullLogo-11" data-name="fullLogo" d="M131.0811,76.2466h10.6152c8.3438,0,13.9678,5.3638,13.9678,13.0366s-5.624,13.0361-13.9678,13.0361h-10.6152v-26.0728Zm10.4668,23.689c6.9277,0,11.3975-4.395,11.3975-10.6523s-4.4697-10.6528-11.3975-10.6528h-7.7109v21.3052h7.7109Z" fill={SECONDARY_MAIN}/>
        <path id="fullLogo-12" data-name="fullLogo" d="M187.1846,76.2466h2.7559v26.0728h-2.7559v-26.0728Z" fill={SECONDARY_MAIN}/>
        <path id="fullLogo-13" data-name="fullLogo" d="M221.457,89.2832c0-7.6357,5.8105-13.2603,13.6699-13.2603,3.7988,0,7.2256,1.2666,9.5352,3.7993l-1.751,1.7505c-2.123-2.1602-4.7305-3.0913-7.71-3.0913-6.2949,0-11.0244,4.6187-11.0244,10.8018s4.7295,10.8013,11.0244,10.8013c2.9795,0,5.5869-.9312,7.71-3.1284l1.751,1.7505c-2.3096,2.5327-5.7363,3.8364-9.5723,3.8364-7.8223,0-13.6328-5.6245-13.6328-13.2598Z" fill={SECONDARY_MAIN}/>
        <path id="fullLogo-14" data-name="fullLogo" d="M291.5654,95.354h-14.5264l-3.1289,6.9653h-2.8682l11.9189-26.0728h2.7197l11.9189,26.0728h-2.9053l-3.1289-6.9653Zm-1.0059-2.2344l-6.2578-14.0049-6.2568,14.0049h12.5146Z" fill={SECONDARY_MAIN}/>
        <path id="fullLogo-15" data-name="fullLogo" d="M327.2559,76.2466h2.7559v23.689h14.6016v2.3838h-17.3574v-26.0728Z" fill={SECONDARY_MAIN}/>
        <path id="fullLogo-16" data-name="fullLogo" d="M347.5928,16.4248h-1.8555v-1.0938h5.0537v1.0938h-1.8555v4.7061h-1.3428v-4.7061Z" fill={PRIMARY_MAIN}/>
        <path id="fullLogo-17" data-name="fullLogo" d="M356.793,21.1308l-.0088-3.48-1.707,2.8667h-.6045l-1.6982-2.792v3.4053h-1.2598v-5.7998h1.1104l2.1709,3.604,2.1377-3.604h1.1016l.0166,5.7998h-1.2588Z" fill={PRIMARY_MAIN}/>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
