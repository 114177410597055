import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const theme = useTheme();

  const PRIMARY_MAIN = theme.palette.primary.main;

  const logo = (
    <Box sx={{ width: 48, height: 48, ...sx }}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 747 661"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Group" transform="translate(0.000000, 4.000000)">
            <circle id="Oval" stroke={PRIMARY_MAIN} strokeWidth="40" cx="394" cy="341" r="296" />
            <circle id="Oval" stroke={PRIMARY_MAIN} strokeWidth="8" cx="353" cy="296" r="296" />
            <path
              d="M268.85,422.38 C295.196667,422.38 317.47,413.193333 335.67,394.82 C354.043333,376.446667 363.23,354.173333 363.23,328 C363.23,301.653333 354.043333,279.38 335.67,261.18 C317.47,242.806667 295.196667,233.62 268.85,233.62 C242.503333,233.62 220.23,242.806667 202.03,261.18 C183.83,279.38 174.73,301.653333 174.73,328 C174.73,354 183.83,376.273333 202.03,394.82 C220.23,413.193333 242.503333,422.38 268.85,422.38 Z M268.85,398.72 C248.916667,398.72 232.19,391.96 218.67,378.44 C205.323333,364.573333 198.65,347.76 198.65,328 C198.65,308.066667 205.323333,291.253333 218.67,277.56 C232.19,263.866667 248.916667,257.02 268.85,257.02 C288.956667,257.02 305.596667,263.866667 318.77,277.56 C332.29,291.08 339.05,307.893333 339.05,328 C339.05,347.76 332.29,364.573333 318.77,378.44 C305.596667,391.96 288.956667,398.72 268.85,398.72 Z M416.53,419 L416.53,277.04 L476.07,375.84 L479.19,375.84 L538.73,276.78 L538.73,419 L562.65,419 L562.65,237 L535.35,237 L477.63,333.2 L419.65,237 L392.35,237 L392.35,419 L416.53,419 Z"
              id="OM"
              fill={PRIMARY_MAIN}
              fillRule="nonzero"
            />
          </g>
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
