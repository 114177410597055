import * as React from 'react';
import { FunctionComponent, useCallback, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitleEnhanced from 'src/components/dialogs/DialogTitleEnhanced';
import NiceModal, { NiceModalHocProps, useModal } from '@ebay/nice-modal-react';
import { isNil } from 'lodash';
import LoginTelephoneForm from 'src/components/dialogs/login/LoginTelephoneForm';
import LoginCodeForm from 'src/components/dialogs/login/LoginCodeForm';
import { useUpdateEffect } from 'react-use';
import useAuth from 'src/hooks/useAuth';
import { useSnackbar } from 'notistack';

export type LoginDialogProps = {
  signUp?: boolean;
};

const LoginDialog: FunctionComponent<LoginDialogProps & NiceModalHocProps> = NiceModal.create(
  ({ signUp }) => {
    const modal = useModal();
    const { enqueueSnackbar } = useSnackbar();
    const { isLoggedIn, error, telephone } = useAuth();

    const [showSignUp, setShowSignUp] = useState<boolean>(!isNil(signUp) ? signUp : true);

    useUpdateEffect(() => {
      if (isLoggedIn) {
        enqueueSnackbar('Logged in successfully.', { variant: 'success' });
        modal.hide();
      }
    }, [isLoggedIn]);

    useUpdateEffect(() => {
      if (error) {
        enqueueSnackbar('Connection failed. Please try again later.', { variant: 'error' });
      }
    }, [error]);

    const toggleSignUpHandler = useCallback((): void => {
      setShowSignUp((show) => !show);
    }, [setShowSignUp]);

    return (
      <Dialog
        open={modal.visible}
        onClose={() => modal.hide()}
        TransitionProps={{
          onExited: () => modal.remove(),
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitleEnhanced onClose={modal.hide}>
          {showSignUp ? 'Signup' : 'Login'}
        </DialogTitleEnhanced>
        {!telephone ? (
          <LoginTelephoneForm showSignUp={showSignUp} onToggleSignup={toggleSignUpHandler} />
        ) : (
          <LoginCodeForm showSignUp={showSignUp} />
        )}
      </Dialog>
    );
  }
);

export default LoginDialog;
