import { useCallback, useEffect, useState } from 'react';
import useGenericRequest, { GenericRequestState } from '../base/useGenericRequest';
import { BaseEntity, DynamicModelFilter, FilterField, OrderDTO } from 'src/@types/entity';
import { isNil } from 'lodash';

export interface GenericRequestStateSearch<ResponseRO> extends GenericRequestState<ResponseRO[]> {
  totalItemCount: number;
  totalPageCount: number;
  hasMore: boolean;
}

interface Options {
  currentPage?: number;
  pageSize?: number;
  filterFields?: FilterField[];
  orders?: OrderDTO[];
  manual?: boolean;
  count?: boolean;
  cache?: boolean;
  throttle?: boolean;
  cacheAutoReload?: boolean;
}

function useCrudSearch<ResponseRO>(
  entity: BaseEntity,
  options?: Options
): GenericRequestStateSearch<ResponseRO> {
  const [items, setItems] = useState<ResponseRO[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalItemCount, setTotalItemCount] = useState<number>(0);
  const [totalPageCount, setTotalPageCount] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const requestCount = isNil(options?.count) ? true : options?.count;
  const currentPage = options?.currentPage || 1;
  const pageSize = options?.pageSize || 100;
  const start = (currentPage - 1) * pageSize;
  const limit = pageSize;

  const countState = useGenericRequest<any>(
    {
      url: `${entity.api.path}/search/count`,
      method: 'POST',
      data: {
        filterFields: options?.filterFields || [],
      },
    },
    {
      manual: options?.manual || !requestCount,
      cache: options?.cache,
      cacheName: entity.api.cacheName,
      cacheAutoReload: options?.cacheAutoReload && requestCount,
      throttle: options?.throttle,
      resultTransformer: (responseData) => responseData,
    }
  );

  useEffect(() => {
    const data = countState.result;
    if (data && data.success) {
      const count = data.result;
      setTotalItemCount(count);
      setTotalPageCount(Math.max(1, Math.ceil(count / pageSize)));
    }
  }, [countState.result]);

  useEffect(() => {
    if (totalItemCount) {
      setTotalPageCount(Math.max(1, Math.ceil(totalItemCount / pageSize)));
    }
  }, [pageSize]);

  const searchState = useGenericRequest<any>(
    {
      url: `${entity.api.path}/search`,
      method: 'POST',
      data: {
        start: start,
        limit: limit,
        filterFields: options?.filterFields || [],
        orders: options?.orders || entity.api.defaultOrders,
      } as DynamicModelFilter,
    },
    {
      manual: options?.manual,
      cache: options?.cache,
      cacheName: entity.api.cacheName,
      cacheAutoReload: options?.cacheAutoReload,
      throttle: options?.throttle,
      resultTransformer: (responseData) => responseData,
    }
  );

  useEffect(() => {
    const data = searchState.result;
    if (data) {
      if (data.success) {
        setItems(data.result);
        setHasMore(
          data.paging.hasMore || data.paging.start + data.paging.limit < data.paging.total
        );
        if (!requestCount) {
          setTotalItemCount(data.paging.total || data.paging.start + data.result.length || 0);
        }
      } else {
        setHasMore(false);
      }
    }
  }, [searchState.result]);

  useEffect(() => {
    if (searchState.error) {
      setHasMore(false);
    }
  }, [searchState.error]);

  useEffect(() => {
    setLoading(searchState.loading || countState.loading);
  }, [searchState.loading, countState.loading]);

  const executeRequest = useCallback((): void => {
    setLoading(true);

    if (requestCount) {
      countState.executeRequest();
    }
    searchState.executeRequest();
  }, [countState, countState.executeRequest, searchState.executeRequest, setLoading]);

  const cancelRequest = useCallback((): void => {
    setLoading(false);

    countState.cancelRequest();
    searchState.cancelRequest();
  }, [countState.cancelRequest, searchState.cancelRequest]);

  return {
    result: items,
    totalItemCount,
    totalPageCount,
    hasMore,
    loading,
    error: searchState.error,
    executed: searchState.executed,
    executeRequest,
    cancelRequest,
  };
}

export default useCrudSearch;
