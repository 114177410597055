import { useTheme } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { PATH_PAGE } from 'src/routes/paths';
import * as React from 'react';
import { useCallback } from 'react';
import useResponsive from 'src/hooks/useResponsive';
import { useLocalStorage } from 'react-use';
import { Button, Stack, Typography } from '@mui/material';
import Link from '@mui/material/Link';

type Props = {};

export default function CookieConsent({ ...other }: Props) {
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'md');

  const [accepted, setAccepted] = useLocalStorage<boolean>('acceptedCookiePolicy', false);

  const acceptHandler = useCallback((): void => {
    setAccepted(true);
  }, [setAccepted]);

  if (accepted) {
    return null;
  }

  return (
    <Stack
      direction={isDesktop ? 'row' : 'column'}
      alignItems={isDesktop ? 'center' : 'start'}
      justifyContent={'space-between'}
      spacing={2}
      sx={{
        p: 2,
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        background: theme.palette.grey[800],
        borderTop: `1px solid ${theme.palette.grey[500_48]}`,
        zIndex: 1,
      }}
    >
      <Typography variant={'body1'} sx={{ color: (theme) => theme.palette.common.white }}>
        This website uses cookies to enhance the user experience.
      </Typography>
      <Stack direction={isDesktop ? 'row' : 'row-reverse'} alignItems={'center'} spacing={2}>
        <Link variant={'body2'} component={RouterLink} to={PATH_PAGE.privacyPolicy}>
          {'Privacy Policy'}
        </Link>
        <Button variant="contained" onClick={acceptHandler}>
          I Understand
        </Button>
      </Stack>
    </Stack>
  );
}
