// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar, { Props as AvatarProps } from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: AvatarProps) {
  return (
    <Avatar
      // src={user?.photoURL}
      // alt={user?.displayName}
      // color={user?.photoURL ? 'default' : createAvatar(user?.displayName).color}
      color={'secondary'}
      {...other}
    >
      {/*{createAvatar(user?.displayName).name}*/}
    </Avatar>
  );
}
