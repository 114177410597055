import * as React from 'react';
import { FunctionComponent, useCallback } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useModal } from '@ebay/nice-modal-react';
import { Controller, useForm } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DIGITS_REGEX } from 'src/utils/regex';
import { doTelephoneAuthentication, resetTelephoneAuthentication } from 'src/redux/slices/auth';
import { useDispatch } from 'src/redux/store';
import useAuth from 'src/hooks/useAuth';

export type LoginDialogProps = {
  showSignUp: boolean;
};

type FormValues = {
  code: string;
};

const LoginCodeForm: FunctionComponent<LoginDialogProps> = ({ showSignUp }) => {
  const modal = useModal();

  const dispatch = useDispatch();
  const { isLoading, prefix, telephone } = useAuth();

  const changePhoneHandler = useCallback((): void => {
    dispatch(resetTelephoneAuthentication());
  }, []);

  const { handleSubmit, control } = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const onSubmit = handleSubmit((data): void => {
    dispatch(
      doTelephoneAuthentication({
        prefix: prefix || '',
        telephone: telephone || '',
        code: data.code,
      })
    );
  });

  return (
    <>
      <DialogContent>
        <form autoComplete="off" noValidate onSubmit={onSubmit}>
          <Stack spacing={3}>
            <Controller
              name="code"
              rules={{
                required: 'Required field.',
                pattern: {
                  value: DIGITS_REGEX,
                  message: 'Digits only.',
                },
              }}
              control={control}
              defaultValue=""
              render={({ field: { ref, ...field }, fieldState: { invalid, error } }) => {
                return (
                  <TextField
                    {...field}
                    inputRef={ref}
                    required
                    fullWidth
                    label={'Code'}
                    type="number"
                    autoFocus
                    inputProps={{
                      maxLength: 6,
                    }}
                    error={invalid}
                    helperText={error?.message}
                  />
                );
              }}
            />

            <Typography variant={'body2'} sx={{ textAlign: 'center', color: 'text.secondary' }}>
              {'We have sent the code to '}
              <Typography variant={'body2'} component="span" sx={{ color: 'secondary.main' }}>
                {`+${prefix}-${telephone}`}
              </Typography>
              {'.'}
            </Typography>
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={changePhoneHandler}>
          Change Phone
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button variant="outlined" color="primary" onClick={modal.hide}>
          Close
        </Button>
        <LoadingButton variant="contained" color="primary" onClick={onSubmit} loading={isLoading}>
          {showSignUp ? 'Signup' : 'Login'}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default LoginCodeForm;
