import axios, { AxiosRequestConfig } from 'axios';
import { configure } from 'axios-hooks';
import { find, get, merge, toString } from 'lodash';
import React, { FunctionComponent } from 'react';
import useAuth from 'src/hooks/useAuth';
import { useDispatch } from 'src/redux/store';
import { logout } from 'src/redux/slices/auth';
import axiosInstance from '../utils/axios';
import { emptyException, ExceptionInfo, exceptions, genericException } from 'src/@types/exception';
import { useSnackbar } from 'notistack';

interface ServerApiManagerProps {}

const ServerApiManager: FunctionComponent<ServerApiManagerProps> = () => {
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const { token } = useAuth();

  const requestHandler = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    if (token) {
      Object.assign(config.headers, { Authorization: `Bearer ${token}` });
    }
    return config;
  };

  const logoutHandler = (): void => {
    dispatch(logout());
  };

  const reloadHandler = (): void => {
    window.location.reload();
  };

  const notificationHandler = (exception: ExceptionInfo) => {
    enqueueSnackbar(
      <>
        {exception.text || 'Network error. Please try again later.'}
        {exception.id && <>{` (Support ID: ${exception.id})`}</>}
      </>,
      {
        variant: 'error',
      }
    );
  };

  const errorMessageHandler = (requestId: any, errorObject: any = null): ExceptionInfo => {
    const exceptionKey = get(errorObject, 'key');

    let exception = find(exceptions, (e) => e.key === exceptionKey);
    if (!exception) {
      exception = genericException;
    }

    if (errorObject) {
      exception = merge(
        {},
        exception,
        errorObject,
        !!requestId ? { id: toString(requestId) } : {}
      ) as ExceptionInfo;
    }

    if (exception.logout) {
      logoutHandler();
    } else if (exception.reload) {
      reloadHandler();
    } else if (exception.notification) {
      notificationHandler(exception);
    }

    return exception;
  };

  const successHandler = (response: any) => {
    const requestId = get(response, 'data.requestId');

    const errorObject = get(response, 'data.errorObject');
    if (errorObject) {
      const exception = errorMessageHandler(requestId, errorObject);
      return Promise.reject(exception);
    }

    const success = get(response, 'data.success');
    if (success === false) {
      const exception = errorMessageHandler(requestId);
      return Promise.reject(exception);
    }

    return response;
  };

  const errorHandler = (error: any) => {
    const errorKey = get(error, 'key');
    if (errorKey) {
      // The error was already handled and returned exception info.
      return Promise.reject(error);
    }

    const errorStatus = get(error, 'response.status');
    if (errorStatus === 401 || errorStatus === 403) {
      return logoutHandler();
    }

    const isCancel = axios.isCancel(error);
    if (isCancel) {
      return Promise.reject(null);
    }

    const errorObject = get(error, 'response.data.errorObject');
    if (errorObject) {
      const requestId = get(error, 'response.data.requestId');
      const exception = errorMessageHandler(requestId, errorObject);
      return Promise.reject(exception);
    }

    const exceptionKey = get(error, 'message');
    const exception = exceptionKey
      ? errorMessageHandler(null, { key: exceptionKey })
      : emptyException;
    return Promise.reject(exception);
  };

  axiosInstance.interceptors.request.use(requestHandler);
  axiosInstance.interceptors.response.use(successHandler, errorHandler);

  // Axios hooks config
  configure({ axios: axiosInstance, cache: false });

  return null;
};

export default ServerApiManager;
