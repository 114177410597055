import * as React from 'react';
import { FunctionComponent, useCallback } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useModal } from '@ebay/nice-modal-react';
import { Controller, useForm } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import { PATH_PAGE } from 'src/routes/paths';
import { DIGITS_REGEX } from 'src/utils/regex';
import { useDispatch } from 'src/redux/store';
import { doTelephoneAuthenticationInit } from 'src/redux/slices/auth';
import useAuth from 'src/hooks/useAuth';

export type LoginDialogProps = {
  showSignUp: boolean;
  onToggleSignup: () => void;
};

type FormValues = {
  prefix: string;
  telephone: string;
};

const LoginTelephoneForm: FunctionComponent<LoginDialogProps> = ({
  showSignUp,
  onToggleSignup,
}) => {
  const modal = useModal();

  const dispatch = useDispatch();
  const { isLoading } = useAuth();

  const toggleSignUpHandler = useCallback((): void => {
    onToggleSignup();
  }, [onToggleSignup]);

  const { handleSubmit, control } = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      telephone: '',
      prefix: '1',
    },
  });

  const onSubmit = handleSubmit((data): void => {
    dispatch(
      doTelephoneAuthenticationInit({
        prefix: data.prefix,
        telephone: data.telephone,
        channel: 'SMS',
      })
    );
  });

  return (
    <>
      <DialogContent>
        <form autoComplete="off" noValidate onSubmit={onSubmit}>
          <Stack spacing={3}>
            <Stack direction={'row'} spacing={3}>
              <Controller
                name="prefix"
                rules={{
                  required: 'Required field.',
                  pattern: {
                    value: DIGITS_REGEX,
                    message: 'Digits only.',
                  },
                }}
                control={control}
                render={({ field: { ref, ...field }, fieldState: { invalid, error } }) => {
                  return (
                    <TextField
                      {...field}
                      inputRef={ref}
                      required
                      fullWidth
                      label={'Prefix'}
                      type="number"
                      autoFocus
                      inputProps={{
                        maxLength: 5,
                      }}
                      error={invalid}
                      helperText={error?.message}
                      sx={{ width: 100 }}
                    />
                  );
                }}
              />

              <Controller
                name="telephone"
                rules={{
                  required: 'Required field.',
                  pattern: {
                    value: DIGITS_REGEX,
                    message: 'Digits only.',
                  },
                }}
                control={control}
                render={({ field: { ref, ...field }, fieldState: { invalid, error } }) => {
                  return (
                    <TextField
                      {...field}
                      inputRef={ref}
                      required
                      fullWidth
                      label={'Phone Number'}
                      type="number"
                      autoFocus
                      inputProps={{
                        maxLength: 20,
                      }}
                      error={invalid}
                      helperText={error?.message}
                    />
                  );
                }}
              />
            </Stack>

            <Typography variant={'body2'} sx={{ textAlign: 'center', color: 'text.secondary' }}>
              {'By continuing, I agree to the OsmalityMatters '}
              <Link component={RouterLink} to={PATH_PAGE.privacyPolicy} onClick={modal.hide}>
                {'Privacy Policy'}
              </Link>
              {' and '}
              <Link component={RouterLink} to={PATH_PAGE.termsOfUse} onClick={modal.hide}>
                {'Terms of Service'}
              </Link>
              {'.'}
            </Typography>
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={toggleSignUpHandler}>
          {showSignUp ? 'Already have an account? Login' : "Don't have an account? Signup"}
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button variant="outlined" color="primary" onClick={modal.hide}>
          Close
        </Button>
        <LoadingButton variant="contained" color="primary" onClick={onSubmit} loading={isLoading}>
          Send Code
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default LoginTelephoneForm;
