export interface ExceptionInfo {
  key: string;
  params?: { key: string; type: string }[] | null;
  title?: string | null;
  text?: string | null;
  notification?: boolean;
  logout?: boolean;
  reload?: boolean;
  id?: string | null;
}

export const genericException: ExceptionInfo = {
  key: 'GenericException',
  params: [],
  title: 'Network error',
  text: 'Network error. Please try again later.',
  notification: true,
  logout: false,
  reload: false,
  id: 'under-the-sun',
};

export const emptyException: ExceptionInfo = {
  key: 'EmptyException',
  params: [],
  title: null,
  text: null,
  notification: false,
  logout: false,
  reload: false,
  id: 'over-the-bridge',
};

export const exceptions: ExceptionInfo[] = [
  {
    key: 'InvalidTokenException',
    params: [],
    logout: true,
  },
];
