import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch } from '../store';
import {
  AuthenticationResultDTO,
  AuthState,
  TelephoneAuthenticationInitRequestDTO,
  TelephoneAuthenticationRequest,
} from 'src/@types/auth';
import { ResultRO } from 'src/@types/server';
import ReactGA from 'react-ga4';

const initialState: AuthState = {
  isLoading: false,
  error: null,
  prefix: null,
  telephone: null,
  token: null,
  isLoggedIn: false,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.error = action.payload;
    },

    doTelephoneAuthenticationInitSuccess(state, action) {
      state.prefix = action.payload.prefix;
      state.telephone = action.payload.telephone;
    },

    doTelephoneAuthenticationSuccess(state, action) {
      state.token = action.payload;
      state.isLoggedIn = true;
      state.prefix = null;
      state.telephone = null;
    },

    resetTelephoneAuthentication(state) {
      state.prefix = null;
      state.telephone = null;
    },

    logout(state) {
      state.token = null;
      state.isLoggedIn = false;
      state.prefix = null;
      state.telephone = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { resetTelephoneAuthentication, logout } = slice.actions;

// ----------------------------------------------------------------------

export function doTelephoneAuthenticationInit(dto: TelephoneAuthenticationInitRequestDTO) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: ResultRO<void> } = await axios.post('/auth/telephone/init', dto);
      const { success } = response.data;

      if (success) {
        dispatch(
          slice.actions.doTelephoneAuthenticationInitSuccess({
            prefix: dto.prefix,
            telephone: dto.telephone,
          })
        );
      } else {
        throw new Error('Request failed');
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(slice.actions.stopLoading());
    }
  };
}

// ----------------------------------------------------------------------

export function doTelephoneAuthentication(dto: TelephoneAuthenticationRequest) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: ResultRO<AuthenticationResultDTO> } = await axios.post(
        '/auth/telephone',
        dto
      );
      const token = response.data.result?.token;
      const created = !!response.data.result?.created;

      if (token) {
        dispatch(slice.actions.doTelephoneAuthenticationSuccess(token));

        ReactGA.event({
          category: 'Users',
          action: created ? 'User Registration' : 'User Login',
        });
      } else {
        throw new Error('Invalid token');
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(slice.actions.stopLoading());
    }
  };
}

// ----------------------------------------------------------------------
